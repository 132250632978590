<style scoped lang="less">
.full {
  padding: 0;
  margin: 0;
  height: 100%;
}
.followMenu-content {
  width: 100%;
  height: 100vh;
  .followMenu-header {
    cursor: default;
  }
  .el-header {
    padding: 0 10px;
    margin-bottom: 10px;
    /deep/ .el-menu--horizontal > .el-menu-item {
      height: 32px;
      line-height: 32px;
    }
  }
  .followMenu-main {
    background: #fff;
  }
}
</style>
<template>
  <el-container class="followMenu-content" style="height: 100%">
    <el-header class="followMenu-header" height="auto">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">手术信息</el-menu-item>
      </el-menu>
    </el-header>
    <el-container style="overflow: auto">
      <el-aside class="followMenu-menu" width="auto"></el-aside>
      <el-main class="full followMenu-main main-content">
        <router-view :info="currentPatient" />
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
export default {
  name: 'followMenu',
  components: {},
  props: {
    path: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      activeIndex: '1',
      currentPatient: null,
      followPath: ''
    }
  },
  computed: {},
  watch: {
    path: {
      handler(val) {
        if (val) {
          this.followPath = val
          localStorage.setItem('followPath', JSON.stringify(val))
        }
      },
      immediate: true
    },
    $route(to) {
      if (to.path) {
        this.init()
      }
    }
  },
  created() {},
  mounted() {
    this.getCurrentPatient()
    this.init()
    if (this.followPath === '') {
      this.followPath = JSON.parse(localStorage.getItem('followPath'))
    }
  },
  methods: {
    init() {
      if (this.$route.path.includes('ReportPrint')) {
        this.activeIndex = '2'
      } else if (this.$route.path.includes('Pacs')) {
        this.activeIndex = '3'
      } else if (this.$route.path.includes('Equipment')) {
        this.activeIndex = '5'
      } else if (this.$route.path.includes('Nursing')) {
        this.activeIndex = '6'
      } else {
        this.activeIndex = '1'
      }
    },
    handleSelect(key) {
      const query = this.$route.query
      const queryParams = {
        moduleCode: query.moduleCode ? `&moduleCode=${query.moduleCode}` : '',
        moduleId: query.moduleId ? `&moduleId=${query.moduleId}` : '',
        operationId: query.operationId ? `&operationId=${query.operationId}` : '',
        followId: query.followId ? `&followId=${query.followId}` : ''
      }
      const constructRoute = (i, params) => {
        const routeQuery = Object.values(params).filter(Boolean).join('')
        this.$router.push(`${i}${routeQuery ? `?${routeQuery}` : ''}`)
      }
      if (key === '1') {
        constructRoute(this.followPath, queryParams)
      }
    },
    getCurrentPatient() {
      let url = `/v1/webconsole/study/operation/get/${this.$route.query.operationId}`
      this.$api.get(url).then((res) => {
        this.currentPatient = res.data.data
      })
    }
  }
}
</script>
